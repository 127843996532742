import React from 'react';

function TitleServices() {
  return (
    <div className='titleServices'>
      <h2>
        O Sucesso de <strong>de sua empresa </strong>
        Começa <strong>aqui!</strong>
      </h2>
      <p>
        A nossa empresa Atenta as novidades do mercado, a Art Toldos  utiliza as mais modernas técnicas de produção e matéria-prima, afim de oferecer um resultado final excelente. 
        <br/>
        <br/>
        Confie na nossa expertise para transformar os seus projetos em realidade.
      </p>
    </div>
  )
}

export default TitleServices;